@mixin flex($direction, $justifyContent, $alignItems) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin flex-centers {
  @include flex(row, center, center)
}

@mixin flex-column-centers {
  @include flex(column, center, center)
}

@mixin flex-space-between-center {
  @include flex(row, space-between, center)
}

@mixin flex-column-space-between-center {
  @include flex(column, space-between, center)
}

