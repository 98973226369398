.main-content {
  position: relative;
  min-height: 100vh;
  margin-left: $sidebar-width;
  padding: 30px;
  padding-bottom: 120px;

  .container {
    height: auto;
  }

}