.container {
  width: 100%;
  height: 100%;
  &-fluid {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 575px) {
  .hidden-xs { display: none !important; }
}

@media (min-width: 576px) {
  .container, 
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .hidden-sm { display: none !important; }
}

@media (min-width: 768px) {
  .container, 
  .container-md, 
  .container-sm {
    max-width: 720px;
  }
  .visible-md { display: block !important; }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-md { display: none !important; }
}

@media (min-width: 992px) {
  .container, 
  .container-lg, 
  .container-md, 
  .container-sm {
    max-width: 960px;
  }
  .hidden-lg { display: none !important; }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .container, 
  .container-lg, 
  .container-md, 
  .container-sm, 
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1440px) {
  .container, 
  .container-lg, 
  .container-md, 
  .container-sm, 
  .container-xl,
  .container-xxl {
    // max-width: 100%;
    max-width: 1380px;
  }
}



// General Mobile
@media (max-width: 767px) {
  .flex-row-to-column-mobile {
    flex-direction: column;
  }
}
