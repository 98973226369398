.card {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  border: 1px solid $border-primary;
  border-radius: $default-border-radius;
  width: 100%;
  margin: 15px;
  background-color: rgba(99, 205, 246, 0.2);

  &__content {
    &__value {
      h4 {
        font-weight: 700 !important;
      }
    }
  }

  .anticon {
    font-size: 5rem;
    color: rgba(99, 205, 246, 0.2);
  }
}
