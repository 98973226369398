.ant-modal {
  &-register {
    &__content {
      padding: 30px 0;
    }
    &__image { 
      height: 100px;
      margin-bottom: 45px;
    }
    &__title {
      margin-bottom: 15px;
    }
    &__message {
      margin-bottom: 30px;
    }
    &__button {}
  }
}

.ant-modal-no-padding {
  .ant-modal-content {
    .ant-modal-body {
      padding: 0;
    }
  }
}