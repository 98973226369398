.modal-tracking {
  .ant-btn {
    height: $form-height;
  }
  .ant-input-search {
    height: $form-height;
  }
  .ant-select-selector,
  .ant-select-selection-search-input,
  .ant-input {
    height: $form-height !important;
  }
  .ant-select-selection-item {
    line-height: $form-height !important;
  }
  .ant-form-small .ant-form-item-label > label {
    height: 14px;
    font-size: 12px;
    padding: 0 !important;
  }
  .ant-form-vertical .ant-form-item-label {
    padding: 0;
  }
}
