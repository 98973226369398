// Ant Button

.ant-btn.ant-btn-secondary:hover, .ant-btn.ant-btn-secondary:focus {
  background-color: $bg-secondary;
  color: white;
}

// End of Ant Button

.btn {
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  outline: none;
}

// ------------------------------------------------------------------------------------------------------------------------------------------------------------

// Button Type

  .btn-primary {
    background-color: $color-primary;
    border: 1px solid $color-primary;
    color: white;
  }

  .btn-primary-outline {
    background-color: transparent;
    border: 1px solid $color-primary;
    color: $color-primary;
  }

  .btn-white {
    background-color: $color-white;
    border: 1px solid $color-white;
    color: white
  }

  .btn-white-outline {
    background-color: transparent;
    border: 1px solid $color-white;
    color: $color-white;
  }

// End of Button Type

// ------------------------------------------------------------------------------------------------------------------------------------------------------------

// Button Size

  .btn-medium {
    font-size: 14px;
    font-weight: 600;
    height: 40px !important;
  }

// End of Button Size

// ------------------------------------------------------------------------------------------------------------------------------------------------------------

// Button Shape

  .btn-round {
    border-radius: 25px;
  }

// End of Button Shape

// ------------------------------------------------------------------------------------------------------------------------------------------------------------

// Button Font Weight

  .btn-font-normal {
    
  }

  .btn-font-semi-bold {
    font-weight: 600 !important;
  }

// End of Button Font Weight

// ------------------------------------------------------------------------------------------------------------------------------------------------------------


// Misc

.oval {
  border-radius: 50%;
  border: 1px solid #333;
  color: #333;
}

.oval-default {
  border: 1px solid #333 !important;
  color: #333 !important;
}

.oval-white {
  border: 1px solid white !important;
  color: white !important;
}

.shape {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

// ANT BTN

.ant-btn {

  border-radius: 2px;
  font-weight: 900 !important;

  &-primary {
    color: white !important;
  }

  // TYPE
  &-secondary {
    background-color: $color-secondary;
    color: white;
    border: none;
    &:hover {
      background-color: $color-secondary;
      color: white;
      border: none;
    }
  }

  // SIZE
  &-xl {
    font-size: 16px;
    padding: 10px 100px;
    height: auto;
    border-radius: 5px;
  }

  &-xxl {
    font-size: 18px;
    padding: 15px 90px;
    height: auto;
    border-radius: 5px;
  }

}

// ------------------------------------------------------------------------------------------------------------------------------------------------------------

// Responsive

  @media (max-width: 575px) {
    .ant-btn {
      &-xxl {
        font-size: 14px;
        padding: 15px 45px;
        height: auto;
        border-radius: 5px;
      }
    }
  }

  // @media (min-width: 576px) {}

  // @media (min-width: 768px) {}

  // @media (min-width: 992px) {}

  // @media (min-width: 1200px) {}

