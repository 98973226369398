.login {
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;

  &-container {
    @include flex-centers;
  }

  &-wrapper {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: $default-border-radius;
  }

  &__brand-logo {
    height: 75px !important;
  }

  button[type='submit'] {
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 15px;
  }

  label.ant-form-item-required {
    color: $color-primary;
  }

  input,
  .ant-input-affix-wrapper {
    color: white;
    background-color: transparent;
    &:hover {
      color: white;
    }
    &.ant-input-password {
      .anticon {
        color: white;
      }
    }
  }

  // Form Error still transparent and color white
  .ant-form-item-has-error .ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-form-item-has-error .ant-input:hover,
  .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    color: white;
    background-color: transparent;
  }

  // Make Form disabled still transparent
  .ant-input[disabled] {
    color: white;
    background-color: transparent !important;
  }

  .ant-form-item-explain,
  .ant-form-item-extra {
    margin-top: 5px;
  }
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $color-secondary inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

@media (max-width: 600px) {
  .login {
    height: calc(100 * var(--vh));
    overflow: hidden;
    &-container {
      height: 100%;
    }
    &-wrapper {
      margin: 15px;
      background-color: transparent;
      box-shadow: none;
      border: none;
    }
  }
}

@media (min-width: 601px) {
  .login {
    min-height: 500px;
    height: 100vh;
    &-container {
      height: 100%;
    }
  }
}
