/* Font */

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');

/* Body */

html,
body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-family: 'Quicksand', sans-serif !important;
    font-size: $font-size;
    line-height: 1.75em;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

/* headings */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Quicksand', sans-serif !important;
    font-weight: 600;
    line-height: 1.5em;
    margin: 0;
}

h1 { font-size: 3rem; }
h2 { font-size: 2.2rem; }
h3 { font-size: 1.9rem; }
h4 { font-size: 1.5rem; }
h5 { font-size: 1.2rem; }
h6 { font-size: 1rem; }

/* paragraph */

p {
    font-size: $font-size;
    margin: 0 0 20px;
    /* color: #999; */
    font-weight: 500;
    font-family: 'Quicksand', sans-serif !important;
    letter-spacing: .5px;
    color: #555;
}

ol, ul, dl { margin-bottom: 0 !important }

.heading-bordered {
  border-bottom: 2px solid $color-primary;
  width: fit-content;
  padding-right: 30px;
}

// RESPONSIVE

  @media only screen and (max-width: 767px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 { line-height: 1.5em; }
  }

// EXTRA SMALL

  @media only screen and (max-width: 576px) {
    .container {}
    .thumbnail {
      grid-template-columns: 1fr;
    }
  }
  
  // SMALL
  @media only screen and (min-width: 576px) {
    .container {}
  }
  
  // MEDIUM
  @media only screen and (min-width: 768px) {
    .container {}
  }
  
  // LARGE
  @media only screen and (min-width: 992px) {
  }
  
  // EXTRA LARGE
  @media only screen and (min-width: 1200px) {
  }
  
  // EXTRA EXTRA-LARGE 
  @media only screen and (min-width: 1440px) {
    .container {
      max-width: 1300px;
    }
  }
