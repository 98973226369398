.page-dashboard {
  &__summary-cards {
    .container {
      display: flex;
      .card {
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__tab-buttons {
    padding: 0 30px !important;
    border-bottom: 1px solid $border-grey;
    .tab-button {
      margin-right: 15px;
    }
  }

  .ant-table-row {
    &:hover {
      .person-data {
        .ant-collapse {
          &-item {
            // background-color: transparent !important;
          }
        }
      }
    }
  }

}

.person-data {
  .ant-collapse {
    &-item {
      border-bottom: none !important;
      // background-color: white;
    }
    // header is inside item
    &-header {
      color: $color-dark !important;
      font-size: 1rem;
      background-color: transparent !important;
      padding: 5px 0px 5px 20px !important;
      .anticon {
        left: 0 !important;
        top: 10px !important;
      }
    }
    // content is inside item
    &-content {
      font-size: .9rem;
    }
  }
  
}