.table {
  &-control {
    .ant-btn {
      height: 40px;
    }
    .ant-input-search {
      height: 40px;
      .ant-input {
        font-size: 12px
      }
    }
  }
}

.ant-table {
  // please check at https://www.color-hex.com/color/e1d0a6
  &-thead > tr > th {
    background-color: #63cdf6 !important;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  &-tbody > tr.ant-table-row:hover > td {
    background-color: #effafe !important;
  }
  &-expanded-row {
    .ant-table {
      background-color: transparent !important;
      &-thead > tr > th {
        background-color: #dff5fd !important;
        .ant-table-cell {
          background-color: $bg-light-grey;
        }
      }
    }
    & > td, 
    &:hover > td {
      background-color: #d0f0fc !important;
    }
  }
}