.ant-layout-footer {
  background-color: white !important;
  border-top: 1px solid $border-light-grey;
  font-size: .9rem !important;
}

//
// --------------------------------------------------------------------------------------------------------------------------------
//
// Responsive General

  // EXTRA SMALL
  @media only screen and (max-width: 575px) {
    
  }

  // SMALL
  @media only screen and (min-width: 576px) {
    
  }

  // MEDIUM
  @media only screen and (min-width: 768px) {
    
  }

  // LARGE
  @media only screen and (min-width: 992px) {

  }

  // EXTRA LARGE
  @media only screen and (min-width: 1200px) {

  }

  // EXTRA EXTRA-LARGE 
  @media only screen and (min-width: 1440px) {
    
  }

//
// --------------------------------------------------------------------------------------------------------------------------------
//
// Responsive Mobile Focuse

  @media only screen and (max-width: 767px) {
    
  }