.ant-layout-header {

  background-color:white !important;
  border-bottom: 1px solid $border-light-grey;
  padding: 0 30px !important;
  font-size: 1.3rem;
  text-transform: uppercase;
  width: 100%;
  @include flex(row, space-between, center)
}



@media only screen and (max-width: 767px) {

}

@media only screen and (min-width: 768px) {

}