// .ant-layout-sider

.main-sider {
  
  background: rgba(77,189,225, .1) !important;
  border: none;
  height: 100vh !important; // set important or set this css inline on <Sider />
  min-height: 450px;
  position: fixed !important; // set important or set this css inline on <Sider />
  left: 0;
  width: 200px;
  overflow: auto;

  .container {
    padding: 30px;
  }

  .ant-layout-sider-children {
    position: relative;
  }

  &__brand-logo {
    height: 45px;
    z-index: 1;
    transition: all .2s ease;
    margin: 30px 0;
  }

  &.ant-layout-sider-collapsed {
    .main-sider__brand-logo {
      height: 20px;
      margin: 0px;
      margin-left: -20px;
      padding-top: 0px !important;
    }
  }

  .ant-menu {
    background-color: transparent !important;
    display: flex;
    flex-direction: column;
    // height: calc(100% - 60px - 30px);
    // height: calc(100%);
    &.ant-menu-inline-collapsed {
      // height: calc(100%);
    }
    &-inline,
    &-vertical,
    &-vertical-left,
    &-vertical-right {
      border-right: none;
      .ant-menu-item {
        &::after {
          border-right: none !important;
        }
      }
      padding: 0;
    }
    &-item {
      color: #333;
      padding: 0px 30px;
      span {
        a {
          color: #333;
          &:hover {
            color: $color-primary;
          }
        }
      }
      .anticon {
        transition: all .4s linear;
      }
      &-selected {
        color: $color-primary;
        span {
          a {
            color: $color-primary !important;
          }
        }
      }
      &:last-child {
        // margin-top: auto;
      }
      &:active {
        background-color: transparent !important;
      }
      &:hover {
        .anticon {
          color: $color-primary !important;
        }
      }
    }
    &:not(.ant-menu-horizontal) {
      .ant-menu-item-selected {
        background-color: transparent;
      }
    }
  }

}