.ant-form {
  &-item {
    margin-bottom: 15px;
    &-explain {
      margin-top: 5px;
      font-size: 12px;
      min-height: 18px;
    }
  }
  &-item-explain {
    position: absolute;
    right: 0;
    top: 38px;
    font-size: 12px !important;
  }
}

.ant-modal {
  .ant-btn {
    height: $form-height-lg;
  }
  .ant-input-search {
    height: $form-height-lg;
  }
}

.ant-picker {
  height: $form-height-lg;
}

.ant-select-selector,
.ant-select-selection-search-input,
.ant-input {
    height: $form-height-lg !important;
}
